<template>
  <div class="fps-login">
    <div class="fps-login-container">
      <form class="wl-form" v-on:submit="handleLogin($event)">
        <div class="wl-form__container">
          <h1 class="wl-form__title">{{ cms.page.login_title }}</h1>
          <TzMarkdown
            class="fps-login__sub-text"
            :content="cms.page.sub_text"
            v-if="cms.page.sub_text"
          />
          <TzMarkdown
            v-for="item in renderIf({ condition: hasInvalidInput })"
            :key="item"
            class="form-message form-message--error"
            :content="errorText"
          />
          <div class="wl-form__wrapper">
            <div class="wl-form__form-group">
              <input
                class="wl-form__input"
                :class="{ 'wl-form__input--invalid': invalidInput.email }"
                type="email"
                name="email"
                v-model="email"
                :placeholder="cms.page.email_label"
                aria-label="Email Address"
              />
            </div>

            <div class="wl-form__form-group">
              <input
                class="wl-form__input"
                :class="{ 'wl-form__input--invalid': invalidInput.password }"
                type="password"
                name="password"
                v-model="password"
                :placeholder="cms.page.password_label"
                aria-label="Password"
              />
            </div>

            <label class="wl-form__form-group wl-form__form-group--checkbox">
              <input
                type="checkbox"
                name="remember"
                class="wl-form__input"
                v-model="rememberMe"
              />
              <span class="wl-form__label-text">{{
                cms.page.remember_label
              }}</span>
            </label>

            <button
              class="wl-form__button button button--purple"
              :class="{ 'wl-form__button--disabled': isLoggingIn }"
              type="submit"
              :disabled="isLoggingIn"
            >
              <span class="wl-form__button-text">{{
                cms.page.button_text
              }}</span>
            </button>
          </div>
          <div class="wl-form__link">
            <a :href="cms.page.link_url">{{ cms.page.link_text }}</a>
          </div>
        </div>
      </form>
      <TzMarkdown
        class="fps-login__post-text"
        :content="cms.page.post_text"
        v-if="cms.page.post_text"
      />
    </div>
  </div>
</template>

<script>
import Meta from '@/assets/js/shared/misc/meta.mjs';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
const pageCms = require(`@/assets/cms/pages/login.json`);
import renderIf from '@/assets/js/shared/helpers/renderIf';
import getRegex from '@/assets/js/shared/helpers/getRegex';

export default {
  name: 'login',
  components: { TzMarkdown },
  computed: {
    hasInvalidInput() {
      return this.invalidInput.email || this.invalidInput.password;
    }
  },
  data() {
    return {
      defaultErrorText:
        'Unable to login. Please verify your email address and password below.',
      email: null,
      errorText: null,
      isLoggingIn: false,
      invalidInput: {
        email: false,
        password: false
      },
      password: null,
      rememberMe: false,
      renderIf
    };
  },
  methods: {
    handleError(error) {
      this.isLoggingIn = false;
      this.errorText = error ? error : this.defaultErrorText;
    },
    handleSuccessfulLogin() {
      this.invalidInput.email = false;
      this.invalidInput.Password = false;
      this.isLoggingIn = false;
      if (this.rememberMe) {
        localStorage.setItem('rememberMe', this.email);
      }
      const { href } = this.$router.resolve({
        name: 'dashboard-reports'
      });
      location.href = href;
    },
    async handleLogin($event) {
      $event.preventDefault();
      this.isLoggingIn = true;
      this.invalidInput.email = false;
      this.invalidInput.password = false;
      this.validateEmailAndPassword();

      if (this.hasInvalidInput) {
        return;
      }

      /** Company/Testing emails will have company domain, all customers are fps domain **/
      const domain =
        this.email.endsWith('@traziventures.com') ||
        this.email.endsWith('@trazi.com')
          ? 'traziventures.com'
          : 'freepeoplesearch.com';

      /** Attempt to login **/
      try {
        const login = await this.$travellingSDK.AuthDomain.login(
          {
            email: this.email,
            password: this.password,
            domain: 'default'
          },
          domain
        );
        /** If call is successful, check for errors **/
        if (!login.msg || login.type) {
          let error = null;
          switch (login.type) {
            case 'locked':
              this.invalidInput.email = true;
              error = `This account has been locked.<br/>Please <a href="${this.cms.page.forgot_link}">reset your password</a> to login.`;
              break;
            case 'password-error':
              /** Invalid Password will highlight password input and change default error message **/
              this.invalidInput.password = true;
              error =
                'Password does not meet requirements. Please verify and resubmit.';
              break;
            default:
              /** All other errors will highlight both inputs **/
              this.invalidInput.email = true;
              this.invalidInput.password = true;
          }
          /** Display error to user **/
          this.handleError(error);
          return;
        }
      } catch (err) {
        this.$bugsnag.notify(err);
        /** On failure to hit API, display message with contact info **/
        this.invalidInput.email = true;
        this.invalidInput.password = true;
        this.handleError();
        return;
      }

      this.handleSuccessfulLogin();
    },
    validateEmailAndPassword() {
      if (!this.email && !this.password) {
        this.invalidInput.email = true;
        this.invalidInput.password = true;
        this.handleError('Please enter your email address and password below.');
        return;
      }

      if (
        !this.email ||
        !getRegex({ regexType: 'emailRegex' }).test(this.email)
      ) {
        this.invalidInput.email = true;
        this.handleError('Please enter a valid email address');
        return;
      }

      if (!this.password) {
        this.invalidInput.password = true;
        this.handleError('Please enter a valid password');
      }
    }
  },
  mounted() {
    /** Pre-fill user's email in the form if "Remember me" is checked **/
    if (!localStorage.rememberMe) {
      return {};
    }
    const userEmail = localStorage.getItem('rememberMe');
    this.email = userEmail;
    if (userEmail) {
      this.rememberMe = true;
    }
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: pageCms,
          global: require(`@/assets/cms/global.json`)
        };
      }
    }
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
